<template>
  <div class="boxTr">
    <el-row class="dash-board">
      <el-col :span="24">
        <p class="in1">学员姓名：</p>
        <el-input placeholder="输入姓名" v-model="stuName" style="width: 150px" class="e-inps"></el-input>
        <p class="in2">班级名称：</p>
        <!-- <el-input placeholder="输入班级" v-model="className" style="width: 200px" class="in3"></el-input> -->
        <el-select style="width: 190px" class="in3" placeholder="请选择班级" v-model="className" filterable clearable>
          <el-option v-for="(item,index) in classData"
                    :key="index"
                    :label="item.className"
                    :value="item.classId"/>
        </el-select>
        <p class="in2" style="left: 550px;">状态：</p>
        <el-select style="width: 190px; left: 600px;" class="in3" placeholder="请选择班级" v-model="status" filterable clearable>
          <el-option v-for="(item,index) in statelist"
                    :key="index"
                    :label="item.value"
                    :value="item.id"/>
        </el-select>
        <div id="" class="select-btn-box">
          <el-button type="primary" @click="getOne" v-permission="['Train:select']" class="inquire">查询</el-button>
          <el-button type="warning" @click="reset" class="reset">重置</el-button>
        </div>
      </el-col>
    </el-row>
    <el-row class="bg">
      <el-table
        :data="tableData"
        style="width: 100%;">
        <el-table-column type="selection"></el-table-column>
        <el-table-column
          label="序号"
          align="center"
          width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.assessId }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="姓名"
          align="center"
          width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.stuName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="性别"
          align="center"
          width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.sex==0">男</span>
            <span v-if="scope.row.sex==1">女</span>
          </template>
        </el-table-column>
        <el-table-column
          label="班级"
          align="center"
          width="190">
          <template slot-scope="scope">
            <span>{{ scope.row.className }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="单位名称"
          align="center"
          width="250">
          <template slot-scope="scope">
            <span>{{ scope.row.depName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="联系电话"
          align="center"
          width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.phone }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="状态"
          align="center"
          width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.status==1">待评估</span>
            <span v-if="scope.row.status==2">已评估</span>
          </template>
        </el-table-column>
        <el-table-column
          label="评估时间"
          align="center"
          width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.status==1">-</span>
            <span v-if="scope.row.status==2">{{scope.row.updateTime}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" v-if="scope.row.status==2" type="primary" v-permission="['Train:see']" @click="see(scope.row.assessId)">查 看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top:20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        layout="total,sizes,  prev, pager, next, jumper"
        :total="total"
        class="pag">
      </el-pagination>
    </el-row>
    <el-dialog
      title="评估详情"
      :data="details"
      :visible.sync="centerDialogVisibleTwo"
      width="50%"
      center>
      <el-row class="table-box">
        <el-col :span="8">
          <div class="title">
            <span>给班主任打分</span>
          </div>
          <div class="content">
            <span>{{details.bzrGrade}} 分</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="title">
            <span>给老师打分</span>
          </div>
          <div class="content">
            <span>{{details.teacherGrade}} 分</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="title">
            <span>给课程打分</span>
          </div>
          <div class="content">
            <span>{{details.courseGrade}} 分</span>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="title">
            <span>课程设计与课程目标</span>
          </div>
          <div class="content">
            <span v-if="details.courseTarget==1">很好</span>
            <span v-if="details.courseTarget==2">好</span>
            <span v-if="details.courseTarget==3">一般</span>
            <span v-if="details.courseTarget==4">差</span>
            <span v-if="details.courseTarget==5">很差</span>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="title">
            <span>授课技能与课堂气氛</span>
          </div>
          <div class="content">
            <span v-if="details.courseAtmosphere==1">很好</span>
            <span v-if="details.courseAtmosphere==2">好</span>
            <span v-if="details.courseAtmosphere==3">一般</span>
            <span v-if="details.courseAtmosphere==4">差</span>
            <span v-if="details.courseAtmosphere==5">很差</span>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="title">
            <span>课堂案例中的实用性</span>
          </div>
          <div class="content">
            <span v-if="details.coursePracticality==1">很好</span>
            <span v-if="details.coursePracticality==2">好</span>
            <span v-if="details.coursePracticality==3">一般</span>
            <span v-if="details.coursePracticality==4">差</span>
            <span v-if="details.coursePracticality==5">很差</span>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="title">
            <span>社会实践中的指导性</span>
          </div>
          <div class="content">
            <span v-if="details.courseGuiding==1">很好</span>
            <span v-if="details.courseGuiding==2">好</span>
            <span v-if="details.courseGuiding==3">一般</span>
            <span v-if="details.courseGuiding==4">差</span>
            <span v-if="details.courseGuiding==5">很差</span>
          </div>
        </el-col>
      </el-row>
     <el-row class="row-bg">
        <el-col :span="24">
          <span>对本期班教学环境给予评价</span>
          <p>{{details.environment}}</p>
        </el-col>
        </el-row>
        <el-row class="row-bg">
        <el-col :span="24">
          <span>对本期班校内教学课程安排上给予评价和建议</span>
          <p>{{details.courseArrangement}}</p>
        </el-col>
        </el-row>
        <el-row class="row-bg">
        <el-col :span="24">
          <span>对本期班校外教学实践上给予评价和建议</span>
          <p>{{details.teachingPractice}}</p>
        </el-col>
        </el-row>
        <el-row class="row-bg">
        <el-col :span="24">
          <span>对本期班在教务工作上给予评价和建议</span>
          <p>{{details.teachingWork}}</p>
        </el-col>
        </el-row>
        <el-row class="row-bg">
        <el-col :span="24">
          <span>对本期班教学后勤和保障工作上给予评价和建议</span>
          <p>{{details.teachingLogistics}}</p>
        </el-col>
        </el-row>
        <el-row class="row-bg">
        <el-col :span="24">
          <span>建议培训课程上需要作哪些补充</span>
          <p>{{details.courseReplenish}}</p>
        </el-col>
        </el-row>
        <el-row class="row-bg">
        <el-col :span="24">
          <span>对本期培训的个人心得</span>
          <p>{{details.mineGrade}}</p>
        </el-col>
       </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="centerDialogVisibleTwo = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { getAdminAssessStu, details } from '@/api/training.js'
  import { getAllClassByMoHu } from '@/api/user.js'
  export default {
    name: 'Train',
    data () {
      return {
        classData: [], // 存储班级列表信息
        tableData: [],
        stuName: '',
        className: '',
        status: '',
        statelist: [
          { id: 1, value: '待评估' },
          { id: 2, value: '已评估' }
        ],
        assessId: '',
        details: {},
        centerDialogVisibleTwo: false,
        total: 0,
        pageNum: 1,
        pageSize: 10
      }
    },
    mounted () {
      this.getOne()
      this.reset()
      this.findClass()
    },
    methods: {
      // 获取班级列表
      findClass() {
        getAllClassByMoHu().then(response => {
          this.classData = response.data.data.rows
        })
      },
      getOne () {
        const query = {
          classId: this.className,
          stuName: this.stuName,
          status: this.status,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        getAdminAssessStu(query).then(resp => {
          if (resp.data.code === '0') {
            this.tableData = resp.data.data.rows
            this.total = resp.data.data.total // 查询总条数
          }
        })
      },
      see (assessId) {
        this.centerDialogVisibleTwo = true
        this.assessId = assessId
        details(this.assessId).then(resp => {
          this.details = resp.data.data
        })
      },
      reset() {
        this.className = ''
        this.stuName = ''
      },
      handleSizeChange(e) {
        this.pageSize = e
        this.getOne()
      },
      handleCurrentChange(e) {
        this.pageNum = e
        this.getOne()
      }
    }
  }
</script>

<style scoped>
  .dash-board {
    display: flex;
    background-color: white;
    height: 60px;
    padding: 10px 0;
  }
  /* .dash-board button{margin: 0px 15px;} */
  .bg{margin-top: 15px; padding: 20px; background-color: white;}
  .in1{font-size: 13px; position: absolute; top: 10px; left: 17px}
  .in2{font-size: 13px; position: absolute; top:10px; left: 270px}
  .in3{width: 160px; position: absolute; top: 14px;left: 340px}
  .spani{color: #2c2fd6}
  .e-inps{position: absolute; left: 80px; top: 14px}
  .row-bg{ border: 1px solid #e0e0e0;}
  .row-bg .el-col{ display: flex; align-items: center; height: 45px; overflow: hidden; }
  .row-bg span{ width: 315px; background-color: #f4f4f4; color: #000; font-weight: bold; line-height: 45px; padding-left: 10px;}
  .row-bg p{ width: 380px; height: 45px; padding: 5px; display: flex;align-items: center;margin: 0; color: #4f9e12; overflow-y: auto; overflow-x: hidden; }
  .row-text{line-height: 24px;}
  .select-btn-box{
    position: absolute;
    left: 810px;
    top: 14px;
  }
  /* .table-box{border: 2px solid #b7c2c6;} */
  .table-box .el-col{
    border: 1px solid #eeeeee;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
  .table-box .el-col .title {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f4f4;
    font-weight: bold;
    color: #000; width: 100%;text-align: center;margin: 0;height: 45px;}
    .table-box .el-col .content{
      color: #4f9e12;
      line-height: 25px;
    }
</style>
